<template>
	<div v-if="!hidden">
		<!-- begin::Sticky Toolbar -->
		<ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
			<router-link :to="{ name: 'dash' }">
				<li v-b-tooltip.hover.left="'Dashboard'" class="nav-item my-1" v-if="$route.name !== 'dash'">
					<span class="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary">
						<i class="flaticon-home text-primary"></i>
					</span>
				</li>
			</router-link>
			<router-link :to="{ name: 'admin' }">
				<li v-b-tooltip.hover.left="'Admin'" class="nav-item my-1" v-if="currentUser.role != 'user'">
					<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning">
						<i class="flaticon2-gear text-warning"></i>
					</span>
				</li>
			</router-link>
			<router-link :to="{ name: 'srflist' }" target="_blank">
				<li
					v-b-tooltip.hover.left="'Service Request Form'"
					class="nav-item my-1"
					v-if="(['admin', 'noc', 'staff'].includes(currentUser.role) || currentUser.showsrf == true) && $route.name !== 'srflist'"
				>
					<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-success">
						<i class="flaticon2-writing text-success"></i>
					</span>
				</li>
			</router-link>
			<router-link :to="{ name: 'video' }">
				<li
					v-b-tooltip.hover.left="'Video'"
					class="nav-item my-1"
					v-if="(['admin', 'noc', 'staff'].includes(currentUser.role) || currentUser.showsrf == true) && $route.name !== 'video'"
				>
					<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-info">
						<i class="flaticon-photo-camera text-info"></i>
					</span>
				</li>
			</router-link>
		</ul>
		<!-- end::Sticky Toolbar -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'KTStickyToolbar',
	computed: {
		...mapGetters(['currentUser']),
		hidden() {
			return this.currentUser.role === 'user' && !this.currentUser.showsrf;
		},
	},
};
</script>
