<template>
	<div>
		<div class="d-flex flex-column pt-10 bgi-size-cover bgi-no-repeat rounded-top" :style="{ backgroundImage: `url(${backgroundImage})` }">
			<h4 class="d-flex flex-center rounded-top">
				<span class="text-white">VOIP Calls</span>
				<span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2" @click="update()"> {{ voip.length }} Active </span>
			</h4>
			<div class="text-center mt-2 mb-4">
				<router-link :to="{ name: 'voip' }">
					<button class="btn btn-sm btn-outline-light text-white py-1">View All</button>
				</router-link>
			</div>
		</div>

		<div class="px-3 py-5">
			<perfect-scrollbar :options="{ wheelPropagation: false }" class="scroll pr-3 mr-n3">
				<template v-for="(call, i) in voip">
					<div class="row pr-3 mb-6" :key="i">
						<div class="col-2 d-flex align-items-center">
							<div class="symbol symbol-40 symbol-light-success">
								<span class="symbol-label">
									<span class="svg-icon svg-icon-lg svg-icon-success">
										<inline-svg src="/media/svg/icons/Communication/Active-call.svg" />
									</span>
								</span>
							</div>
						</div>
						<div class="col-10 d-flex flex-column flex-grow-1 justify-content-center">
							<div class="row">
								<div class="col-5">
									<span class="font-weight-bold">
										{{ call.ANI }}
									</span>
								</div>
								<div class="col-2">
									<span class="svg-icon svg-icon-sm svg-icon-dark">
										<inline-svg src="/media/svg/icons/Navigation/arrow-right.svg" />
									</span>
								</div>
								<div class="col-5 pr-2 text-right font-weight-bold">
									<span>{{ call.DNIS }}</span>
								</div>
							</div>
							<div class="row">
								<div class="col-9">
									<div class="font-weight-normal" v-if="call.SiteName" style="overflow-wrap: break-word;">
										<router-link :to="{ name: 'site', params: { id: call.SiteId } }">{{ call.SiteName }}</router-link>
									</div>
								</div>
								<div class="col-3 pr-2 text-right font-weight-normal text-dark-50">
									<span>{{ call.duration }}</span>
								</div>
							</div>
						</div>
					</div>
				</template>
			</perfect-scrollbar>
		</div>
	</div>
</template>
<script>
export default {
	name: 'VOIPDropdown',
	props: {
		voip: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	data() {
		return {};
	},
	computed: {
		backgroundImage() {
			return process.env.BASE_URL + 'media/misc/bg-1.jpg';
		},
	},
	methods: {
		update() {
			this.$emit('update');
		},
	},
};
</script>

<style scoped>
.ps {
	height: 400px;
}
.btn {
	transition: 200ms ease-in-out;
}
.reload {
	position: absolute;
	right: 3px;
	top: 0px;
}
</style>
