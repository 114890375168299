<template>
	<div class="topbar-item">
		<div class="topbar-sites btn btn-icon btn-hover-light-danger btn-sm mr-1" v-b-toggle.notifications>
			<span class="svg-icon svg-icon-xl svg-icon-white topbar-icon">
				<inline-svg src="/media/svg/icons/General/Notifications1.svg" />
			</span>
			<span v-if="sites.length > 0" class="label label-danger count-label">{{ sites.length }}</span>
		</div>

		<!-- begin::Quick Panel -->
		<b-sidebar id="notifications" width="375px" bg-variant="white" right shadow lazy no-header @shown="getSiteAlarms()">
			<div id="kt_quick_panel" class="pt-3" style="overflow: hidden">
				<!--begin::Header-->
				<div class="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
					<ul class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" v-on:click="setActiveTab" data-tab="0" data-toggle="tab" href="#" role="tab" aria-selected="true">
								Site Alarms
							</a>
						</li>
					</ul>
					<div class="offcanvas-close mt-n1 pr-5">
						<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" v-b-toggle.notifications>
							<i class="ki ki-close icon-xs text-muted"></i>
						</a>
					</div>
				</div>
				<!--end::Header-->
				<div class="offcanvas-content px-8">
					<div class="tab-content">
						<b-tabs class="hide-tabs" v-model="tabIndex">
							<b-tab active>
								<div class="scroll pr-7 mr-n8" id="kt_quick_panel_logs">
									<div class="mb-15">
										<div class="d-flex align-items-center rounded p-5 mb-5">
											<div class="search-box">
												<b-form-input class="search-bar" size="sm" v-model="searchText" placeholder="Search" debounce="500" />
												<b-icon-search class="search-icon" />
											</div>
										</div>
										<template v-for="(item, i) in sitesFiltered">
											<!--begin: Item -->
											<router-link :to="{ name: 'site', params: { id: item.SiteId } }" v-bind:key="i">
												<div class="d-flex align-items-center rounded py-1 mb-5">
													<div class="btn btn-sm btn-danger mr-2">
														<img width="30" :src="'/media/img/ICON-' + (item.icon ? item.icon : 'dish') + '.png'" />
													</div>

													<div class="d-flex flex-column flex-grow-1 mr-2">
														<a href="#" class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
															{{ item.Name }}
														</a>
														<span class="text-muted font-size-sm">
															{{ item.date }}
														</span>
													</div>
												</div>
											</router-link>
											<!--end: Item -->
										</template>
									</div>
								</div>
							</b-tab>
						</b-tabs>
					</div>
				</div>
			</div>
		</b-sidebar>
		<!-- end::Quick Panel -->
	</div>
</template>

<script>
export default {
	name: 'Notifications',
	data() {
		return {
			tabIndex: 0,
			searchText: '',
			sites: [],
		};
	},
	computed: {
		sitesFiltered() {
			return this.sites.filter(f => {
				return f.Name && f.Name.toLowerCase().includes(this.searchText.toLowerCase());
			});
		},
	},
	methods: {
		getSiteAlarms() {
			this.$http.get('sitelistalarms').then(({ data }) => {
				this.sites = data.data;
			});
		},
		setActiveTab(event) {
			const tab = event.target.closest('[role="tablist"]');
			const links = tab.querySelectorAll('.nav-link');
			// remove active tab links
			for (let i = 0; i < links.length; i++) {
				links[i].classList.remove('active');
			}

			// set clicked tab index to bootstrap tab
			this.tabIndex = parseInt(event.target.getAttribute('data-tab'));

			// set current active tab
			event.target.classList.add('active');
		},
	},
	mounted() {
		this.getSiteAlarms();
	},
};
</script>

<style scoped lang="scss">
::v-deep .hide-tabs > div:not(.tab-content) {
	display: none;
}
.search-box {
	width: 100%;
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.topbar-sites {
	position: relative;

	.count-label {
		position: absolute;
		top: -7px;
		left: -10px;
	}
}
</style>
