<template>
	<div id="alert-banner">
		{{ message }}
	</div>
</template>

<script>
export default {
	name: 'AlertBanner',
	props: {
		message: {
			type: String,
		},
	},
};
</script>

<style scoped lang="scss">
#alert-banner {
	height: 35px;
	width: 100%;
	background: #fec82e;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	z-index: 10;
}
</style>
