<template>
	<!-- begin:: Header Topbar -->
	<div class="topbar justify-content-end">
		<!--begin: Search -->
		<!-- <b-dropdown size="sm" id="kt_quick_search_toggle" variant="link" toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
			<template v-slot:button-content>
				<div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
					<span class="svg-icon svg-icon-xl topbar-icon">
						<inline-svg src="/media/svg/icons/General/Search.svg" />
					</span>
				</div>
			</template>
			<b-dropdown-text tag="div" class="min-w-md-350px">
				<KTSearchDefault></KTSearchDefault>
			</b-dropdown-text>
		</b-dropdown> -->
		<!--end: Search -->

		<!--begin: VOIP-->
		<b-dropdown
			size="sm"
			variant="link"
			toggle-class="topbar-item text-decoration-none"
			class="mr-3"
			no-caret
			right
			no-flip
			lazy
			@show="getVoipCalls"
		>
			<template v-slot:button-content>
				<div id="topbar-voip" class="btn btn-icon btn-clean btn-dropdown btn-hover-light-success btn-sm mr-3">
					<span class="svg-icon svg-icon-xl topbar-icon">
						<inline-svg src="/media/svg/icons/Communication/Active-call.svg" />
					</span>
					<span v-if="voip.length > 0" class="label label-success count-label">{{ voip.length }}</span>
				</div>
			</template>
			<b-dropdown-text tag="div" class="min-w-md-350px">
				<VoipDropdown :voip="voip" @update="getVoipCalls()" />
			</b-dropdown-text>
		</b-dropdown>
		<!--end: VOIP-->

		<!--begin: ALERTS -->
		<KTQuickPanel></KTQuickPanel>
		<!--end: ALERTS-->

		<!--begin: User Bar -->
		<KTQuickUser></KTQuickUser>
		<!--end: User Bar -->
	</div>
	<!-- end:: Header Topbar -->
</template>

<script>
import KTSearchDefault from '@/view/layout/extras/dropdown/SearchDefault.vue';
import VoipDropdown from '@/view/layout/extras/dropdown/VoipDropdown.vue';
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue';
import KTQuickPanel from '@/view/layout/extras/offcanvas/QuickPanel.vue';

export default {
	name: 'KTTopbar',
	data() {
		return {
			voip: [],
		};
	},
	components: {
		KTSearchDefault,
		VoipDropdown,
		KTQuickUser,
		KTQuickPanel,
	},
	computed: {},
	methods: {
		getVoipCalls() {
			this.$http.get('tsip/currentcalls/account').then(({ data }) => {
				this.voip = data.data;
			});
		},
		handleVisibilityChange() {
			// if (document.visibilityState === "hidden") {
			// 	this.interval.stop();
			// } else {
			// 	startSimulation();
			// }
		},
	},
	mounted() {
		this.getVoipCalls();
		// document.addEventListener('visibilitychange', handleVisibilityChange, false);
	},
};
</script>

<style scoped lang="scss">
::v-deep.topbar {
	.dropdown-toggle {
		padding: 0;
		&:hover {
			text-decoration: none;
		}

		&.dropdown-toggle-no-caret {
			&:after {
				content: none;
			}
		}
	}

	.dropdown-menu {
		margin: 0;
		padding: 0;
		outline: none;
		.b-dropdown-text {
			padding: 0;
		}
	}
}
#topbar-voip {
	position: relative;

	.count-label {
		position: absolute;
		top: -7px;
		left: -10px;
	}
}
</style>
